import axios from 'axios';
import getConfig from 'next/config';
import { getSession } from 'next-auth/react';

const { apiUrl: baseURL } = getConfig().publicRuntimeConfig;

const instance = axios.create({
  baseURL,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

instance.interceptors.request.use(async (request) => {
  const session = await getSession();
  if (session) {
    if (!request.headers) {
      request.headers = {};
    }
    // @ts-ignore
    request.headers.Authorization = `Bearer ${session.access}`;
  }
  return request;
});

export default instance;
