import type { DjangoModel } from '@/models/django';

export interface Product extends DjangoModel {
  name: string;
  sku?: string;
  tva: number;
  category?: number;
  variants: number[];
  image?: string;
}

export interface ProductVariant extends DjangoModel {
  product: number;
  buying_price_htva: number;
  buying_price_tvac: number;
  selling_price_htva: number;
  selling_price_tvac: number;
  tva: number;
  sku: string | undefined;
  lot: string | undefined;
  fabrication_date: string | undefined;
  expiration_date: string | undefined;
  stock: number;
}

export interface ProductVariantMemoized extends ProductVariant {
  display_name: string;
}

export interface Category extends DjangoModel {
  name: string;
}

export const formatVariant = (
  variant?: ProductVariant,
  product?: Product
): string => {
  if (!variant) {
    return '';
  }
  if (!product) {
    return '';
  }
  const values = [variant.sku, variant.lot, variant.expiration_date].filter(
    (t) => !!t
  );
  return `${product.name} ${values.join('-')}`.trim();
};
