import type { DjangoModel } from '@/models/django';
import axios from '@/utils/axios';

class DjangoService<T extends DjangoModel> {
  private api_path: string;

  constructor(api_path: string) {
    this.api_path = api_path;
  }

  // eslint-disable-next-line class-methods-use-this
  async list(urlParams?: URLSearchParams): Promise<T[]> {
    const baseUrl = `/data/${this.api_path}.json`;
    const url = urlParams ? `${baseUrl}?${urlParams.toString()}` : baseUrl;
    const response = await axios.get(url);
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async get(id: number | string): Promise<T> {
    const url = `/data/${this.api_path}/${id}.json`;
    const response = await axios.get(url);
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async update(entry: T): Promise<T> {
    const url = `/data/${this.api_path}/${entry.id}.json`;
    const response = await axios.put(url, entry);
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async create(entry: T): Promise<T> {
    const url = `/data/${this.api_path}.json`;
    const response = await axios.post(url, entry);
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async delete(id: number): Promise<void> {
    const url = `/data/${this.api_path}/${id}.json`;
    await axios.delete(url);
  }
}

export default DjangoService;
