import '../styles/global.css';
import 'react-datepicker/dist/react-datepicker.css';

import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { useEffect } from 'react';

import { RootStoreProvider } from '@/providers/RootStoreProvider';

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{
  session: Session;
}>) {
  useEffect(() => {
    document.getElementById('__next')!.className = 'h-full overflow-hidden';
  }, []);
  return (
    <SessionProvider session={session}>
      <RootStoreProvider>
        <Component {...pageProps} />
      </RootStoreProvider>
    </SessionProvider>
  );
}

export default MyApp;
