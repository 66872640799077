import { enableStaticRendering } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { createContext, useContext } from 'react';

import { RootStore } from '@/stores/RootStore';

const isServer = typeof window === 'undefined';

enableStaticRendering(isServer);

let store: RootStore;

const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = 'StoreContext';

export const useRootStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within a StoreProvider');
  }
  return context;
};

export const useProductStore = () => {
  const { productStore } = useRootStore();
  return productStore;
};

export const useProductVariantStore = () => {
  const { productVariantsStore } = useRootStore();
  return productVariantsStore;
};

export const useCategoryStore = () => {
  const { categoryStore } = useRootStore();
  return categoryStore;
};

export const usePurchaseStore = () => {
  const { purchaseStore } = useRootStore();
  return purchaseStore;
};

export const useBoxStore = () => {
  const { boxStore } = useRootStore();
  return boxStore;
};

export const useBoxVariantStore = () => {
  const { boxVariantsStore } = useRootStore();
  return boxVariantsStore;
};

export const useSaleStore = () => {
  const { saleStore } = useRootStore();
  return saleStore;
};

export const useSaleStatisticsStore = () => {
  const { saleStatisticsStore } = useRootStore();
  return saleStatisticsStore;
};

export const initializeStore = (): RootStore => {
  const currentStore = store ?? new RootStore();

  // For SSG and SSR always create a new store
  if (isServer) return currentStore;
  if (!store) store = currentStore;

  return currentStore;
};

export const RootStoreProvider = ({ children }: { children: ReactNode }) => {
  const currentStore = initializeStore();

  return (
    <StoreContext.Provider value={currentStore}>
      {children}
    </StoreContext.Provider>
  );
};
