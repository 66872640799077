import { prettyDate } from '@/compoments/utils/dates';
import type { Box, BoxVariant, BoxVariantMemoized } from '@/models/box';
import { getBoxVariantPrices } from '@/models/box';
import type {
  Category,
  Product,
  ProductVariant,
  ProductVariantMemoized,
} from '@/models/product';
import { formatVariant } from '@/models/product';
import type { Purchase, PurchaseMemoized } from '@/models/purchase';
import type { Sale } from '@/models/sale';
import type { SaleStatistics } from '@/models/statistics';
import { DjangoStore } from '@/stores/DjangoStore';

const productVariantMemoizer = (
  root: RootStore,
  item: ProductVariant
): ProductVariantMemoized => {
  let display_name = '';
  const { productStore } = root;
  const product = productStore.map[item.product];
  if (product) {
    display_name = formatVariant(item, product);
  }
  return {
    ...item,
    display_name,
  };
};

const boxVariantMemoizer = (
  root: RootStore,
  item: BoxVariant
): BoxVariantMemoized => {
  let display_name = '';
  const {
    boxStore,
    productVariantsStore: { mapMemoized: product_variants },
  } = root;
  if (item.box) {
    const box = boxStore.map[item.box];
    if (box) {
      display_name = `${box.name} ${item.name}`;
    }
  }
  return {
    ...item,
    display_name,
    ...getBoxVariantPrices(item, product_variants),
  };
};
const purchaseMemoizer = (
  root: RootStore,
  item: Purchase
): PurchaseMemoized => {
  let discounted_price_htva = 0;
  let discounted_price_tvac = 0;
  let total_htva = 0;
  let total_tvac = 0;
  let product_name = '';
  let product_tva = 0;
  const { productStore } = root;
  const productVariantStore = root.productVariantsStore;
  const variantID = item.product;
  const variant = productVariantStore.map[variantID];
  if (variant) {
    const productId = variant.product;
    const product = productStore.map[productId];
    if (product) {
      product_name = formatVariant(variant, product);
      const { discount, quantity, unit_price_htva } = item;
      const { tva } = product || 0;
      product_tva = tva;
      discounted_price_htva = unit_price_htva * (1 - discount / 100);
      discounted_price_tvac = discounted_price_htva * (1 + tva / 100);
      total_htva = discounted_price_htva * quantity;
      total_tvac = discounted_price_tvac * quantity;
    }
  }
  return {
    ...item,
    date_str: prettyDate(item.date),
    discounted_price_htva,
    discounted_price_tvac,
    total_htva,
    total_tvac,
    product_name,
    product_tva,
  };
};

export class RootStore {
  productStore: DjangoStore<Product, Product>;

  productVariantsStore: DjangoStore<ProductVariant, ProductVariantMemoized>;

  categoryStore: DjangoStore<Category, Category>;

  purchaseStore: DjangoStore<Purchase, PurchaseMemoized>;

  boxStore: DjangoStore<Box, Box>;

  boxVariantsStore: DjangoStore<BoxVariant, BoxVariantMemoized>;

  saleStore: DjangoStore<Sale, Sale>;

  saleStatisticsStore: DjangoStore<SaleStatistics, SaleStatistics>;

  constructor() {
    this.productStore = new DjangoStore(this, 'products');
    this.productVariantsStore = new DjangoStore(
      this,
      'product_variants',
      productVariantMemoizer
    );
    this.categoryStore = new DjangoStore(this, 'category');
    this.purchaseStore = new DjangoStore(this, 'purchase', purchaseMemoizer);
    this.boxStore = new DjangoStore(this, 'box');
    this.boxVariantsStore = new DjangoStore(
      this,
      'box_variants',
      boxVariantMemoizer
    );
    this.saleStore = new DjangoStore(this, 'sale');
    this.saleStatisticsStore = new DjangoStore(this, 'statistics/sales');
  }
}
