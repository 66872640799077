import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import type { DjangoModel } from '@/models/django';
import DjangoService from '@/services/django';
import type { QUERY_STATUS } from '@/types/queries';

import type { RootStore } from './RootStore';

export class DjangoStore<T extends DjangoModel, M extends T> {
  root: RootStore;

  service: DjangoService<T>;

  memoizedFunction?: (root: RootStore, item: T) => M;

  @observable
  entries: T[] = [];

  @observable
  status: QUERY_STATUS = 'INITIAL';

  @computed
  get entriesMemoized(): M[] {
    return this.entries.map((e) => {
      if (this.memoizedFunction) {
        return this.memoizedFunction(this.root, e);
      }
      return e as M;
    });
  }

  getObjects(from_date?: Date, to_date?: Date): M[] {
    return this.entriesMemoized.filter((e) => {
      // eslint-disable-next-line no-prototype-builtins
      if (e.hasOwnProperty('date')) {
        // @ts-ignore
        const date = new Date(e.date);
        if (from_date && to_date) {
          return date >= from_date && date <= to_date;
        }
        if (from_date) {
          return date >= from_date;
        }
        if (to_date) {
          return date <= to_date;
        }
      }
      return true;
    });
  }

  @computed
  get map() {
    return this.entries.reduce((acc: Record<string, T>, entry: T) => {
      if (entry.id) {
        acc[entry.id] = entry;
      }
      return acc;
    }, {});
  }

  @computed
  get mapMemoized() {
    return this.entriesMemoized.reduce((acc: Record<string, M>, entry: M) => {
      if (entry.id) {
        acc[entry.id] = entry;
      }
      return acc;
    }, {});
  }

  constructor(
    root: RootStore,
    api_path: string,
    computeMemoized?: (root: RootStore, item: T) => M
  ) {
    this.root = root;
    this.service = new DjangoService(api_path);
    this.memoizedFunction = computeMemoized;
    makeObservable(this);
  }

  @action
  async getObject(id: number | string): Promise<T | null> {
    runInAction(() => {
      this.status = 'LOADING';
    });
    try {
      runInAction(() => {
        this.status = 'SUCCESS';
      });
      const entry = await this.service.get(id);
      const entries = this.entries.map((e) => (e.id === entry.id ? entry : e));
      runInAction(() => {
        this.entries = entries;
      });
      return entry;
    } catch (e) {
      runInAction(() => {
        this.status = 'ERROR';
      });
      return null;
    }
  }

  @action
  async listObjects(
    init?: string[][] | Record<string, string> | string | URLSearchParams
  ) {
    try {
      // const params = {
      //   pageNumber: this.countryData.pageNumber,
      //   searchQuery: this.searchQuery,
      //   isAscending: this.countryData.isAscending,
      // };
      const urlParams = new URLSearchParams(init);
      const data: T[] = await this.service.list(urlParams);
      runInAction(() => {
        // Check if this is how we want to do the filtering
        const ids = new Set(data.map((d) => d.id));
        this.entries = [...data, ...this.entries.filter((d) => !ids.has(d.id))];
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  @action
  async deleteObject(id: number) {
    try {
      await this.service.delete(id);
      runInAction(() => {
        this.entries = this.entries.filter((e) => e.id !== id);
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  }

  @action
  async updateObject(entry: T): Promise<T | null> {
    try {
      const data = await this.service.update(entry);
      runInAction(() => {
        this.entries = this.entries.map((e) => (e.id !== entry.id ? e : data));
      });
      return data;
    } catch (error) {
      runInAction(() => {
        this.status = 'ERROR';
      });
      return null;
    }
  }

  @action
  async createObject(entry: T): Promise<T | null> {
    try {
      const data = await this.service.create(entry);
      runInAction(() => {
        this.entries.push(data);
      });
      return data;
    } catch (error) {
      runInAction(() => {
        this.status = 'ERROR';
      });
      return null;
    }
  }
}
