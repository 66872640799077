import type { DjangoModel } from '@/models/django';
import type { ProductVariantMemoized } from '@/models/product';

export interface Box extends DjangoModel {
  name: string;
  description: string;
  price_htva: number;
  variants: number[];
}

export interface VariantProduct extends DjangoModel {
  optional: boolean;
  unit_price_htva: any;
  box_variant?: number;
  product: number;
  quantity: number;
}

export interface BoxVariant extends DjangoModel {
  name: string;
  box?: number;
  products: VariantProduct[];
  price_htva: number;
  price_tvac: number;
}

export interface BoxVariantMemoized extends BoxVariant {
  display_name: string;
  price_htva: number;
  price_tvac: number;
  margin_htva: number;
  margin_tvac: number;
}
export interface BoxVariantPrice {
  price_htva: number;
  price_tvac: number;
  margin_htva: number;
  margin_tvac: number;
}

export const getBoxVariantPrices = (
  item: BoxVariant,
  product_variants: Record<number, ProductVariantMemoized>
): BoxVariantPrice => {
  let price_htva = 0;
  let price_tvac = 0;
  let margin_htva = 0;
  let margin_tvac = 0;
  item.products
    .filter((b) => !b.optional)
    .forEach((box_variant_product) => {
      const variant_product = product_variants[box_variant_product.product];
      if (!variant_product) return;
      const buying_price_htva = variant_product.buying_price_htva || 0.0;
      const { quantity, unit_price_htva: selling_price_htva } =
        box_variant_product;
      const tva = 1 + (variant_product.tva || 21) / 100.0;
      const buying_price_tvac = buying_price_htva * tva;
      const selling_price_tvac = selling_price_htva * tva;
      price_htva += selling_price_htva * quantity;
      margin_htva += (selling_price_htva - buying_price_htva) * quantity;
      price_tvac += selling_price_htva * tva * quantity;
      margin_tvac += (selling_price_tvac - buying_price_tvac) * quantity;
    });
  return {
    price_htva,
    price_tvac,
    margin_htva,
    margin_tvac,
  };
};
